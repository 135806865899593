/* look into reset css to make all browsers match styles

/* Using Material-UI CssBaseline
 - See https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/CssBaseline/CssBaseline.js
*/

body {
  background-color: white;
}

.form {
  width: 100%; /* Fix IE 11 issue. */
  margin-top: 1px;
}

.inblock {
  display: inline-block;
}

/* Material-UI Overrides
  - Alternatively, you can override things in your theme JS.
*/

.MuiFormControlLabel-label {
  user-select: none;
}

.MuiInputLabel-animated {
  user-select: none;
}

.MuiInputLabel-formControl {
  user-select: none;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 0.3;
}
